/* //////////////// STRUCTURE ELEMENTS //////////////// */

* {
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 1100px;
  margin: 0 auto;
}

#top-menu {
  /* height: 45px; */
  width: 100%;
  background: #0375a5;
}

#top-menu a {
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;
}

#top-menu a:hover {
  text-decoration: underline;
}

.top-menu-item {
  display: inline;
  margin-left: 20px;
  line-height: 45px;
  font-size: 18px;
  color: #ffffff;
}

.comingSoon {
  font-size: 12px;
  color: #9513f7;
  font-weight: bold;
}

#main-menu {
  width: 100%;
  height: 90px;
  background: #1ec4b0;
}

.bigName {
  height: 90px;
  padding-top: 20px;
  /* line-height: 90px; */
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
}

.main-menu {
  height: 90px;
  line-height: 90px;
}

.main-menu a {
  color: #ffffff;
  font-size: 12px;
  text-decoration: none;
}

.main-menu a:hover {
  text-decoration: underline;
}

.main-menu-option {
  margin-left: 30px;
}

.main-menu-logo {
  margin-left: 30px;
}

#mobileMenu,
#mobileToggle,
.mobileToggle {
  display: none;
}

#main {
  padding: 60px 0 60px 0;
}

#main h1,
h1 {
  font-size: 52px;
  font-weight: bold;
  line-height: 56px;
}

#main h2 {
  font-size: 26px;
  font-weight: normal;
  line-height: 34px;
}

h2.membershipTitle {
  font-size: 52px;
  font-weight: bold;
  line-height: 56px;
  color: #9513f7;
}

#main h3.card-title {
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
}

.purple-question {
  background: #e3b2ff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}

.purple-question a {
  color: #9513f7;
  text-decoration: none;
}

.card-text {
  font-size: 14px;
}

.card-price {
  font-size: 26px;
  font-weight: bold;
  line-height: 30px;
}

.card-per-year {
  font-size: 11px;
  line-height: 12px;
}

.card-text a {
  color: #9513f7;
  font-weight: bold;
  text-decoration: none;
}

.card-text a:hover {
  text-decoration: underline;
}

.main-button {
  background: #a200ff;
  color: #ffffff;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  padding: 15px 40px 15px 40px;
}

.main-button:hover {
  background: #c054fe;
  color: #ffffff;
}

.main-button.small {
  padding: 10px 40px 10px 40px;
}

.main-button.grey {
  background: #b2b2b2;
  color: #ffffff;
}

.main-button.grey:hover {
  background: #d4d3d3;
  color: #ffffff;
}

#already-member {
  padding: 70px 0 70px 0;
  background: #20233f;
  color: #ffffff;
}

.am-login {
  float: left;
  height: 70px;
  width: 190px;
  background: #a200ff;
  border-radius: 20px;
  text-align: center;
  line-height: 25px;
  padding-top: 20px;
  color: #ffffff;
  border: 1px solid #a200ff;
  font-size: 16px;
}

.am-login:hover {
  background: #d79ff8;
}

.am-retrieve {
  float: left;
  height: 80px;
  width: 210px;
  background: #20233f;
  border-radius: 20px;
  text-align: center;
  line-height: 25px;
  padding-top: 20px;
  border: 1px solid #a200ff;
  margin-left: 160px;
}

.am-retrieve a {
  color: #ffffff;
  text-decoration: none;
}

.am-retrieve a:hover {
  text-decoration: underline;
}

.chat-help {
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
}

#main.login-bg {
  background: url(../images/login-bg.jpg) no-repeat;
  background-size: cover;
  padding: 0;
}

#main.login-bg h2 {
  font-size: 36px;
  font-weight: bold;
  line-height: 42px;
}

.login-modal {
  background: #ffffff;
  padding: 20px;
  width: 40%;
}

.green-x {
  background: #1ec4b0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 26px;
}

.green-x a {
  color: #ffffff;
  text-decoration: none;
}

#tab-login {
  float: left;
  width: 120px;
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #a9a9a9;
  color: #a9a9a9;
  font-size: 14px;
  cursor: pointer;
}

#tab-login.selected {
  color: #000000;
  border-bottom: 1px solid #000000;
}

#tab-login:hover {
  color: #000000;
}

#tab-retrieve-quote {
  float: left;
  width: 120px;
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #a9a9a9;
  color: #a9a9a9;
  font-size: 14px;
  cursor: pointer;
}

#tab-retrieve-quote.selected {
  color: #000000;
  border-bottom: 1px solid #000000;
}

#tab-retrieve:hover {
  color: #000000;
}

#login-form {
  margin-top: 10px;
}

#login-form label {
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  margin-top: 10px;
}

#retrieve-form {
  display: none;
}

#retrieve-form {
  margin-top: 10px;
}

#retrieve-form label {
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  margin-top: 10px;
}

.login-field {
  width: 100%;
  border: 1px solid #9513f7;
  border-radius: 5px;
  height: 35px;
  line-height: 35px;
}

.login-field.w80 {
  width: 80%;
}

.login-submit {
  background: #9513f7;
  color: #ffffff;
  height: 45px;
  line-height: 45px;
  width: 100%;
  border-radius: 18px;
  font-weight: bold;
  font-size: 14px;
  border: none;
}

.login-submit.w80 {
  width: 80%;
}

.dropdown {
  width: 100%;
  border: 1px solid #9513f7;
  border-radius: 5px;
  height: 35px;
  line-height: 35px;
}

.dropdown.w80 {
  width: 80%;
}

.greyBtn {
  background: #b2b2b2;
  color: #ffffff;
  height: 45px;
  line-height: 45px;
  width: 18%;
  border-radius: 20px;
  font-weight: bold;
  font-size: 14px;
  border: none;
  margin-left: 2%;
  text-align: center;
}

.greyBtn:hover {
  background: #d4d3d3;
}

.greyBtn a {
  color: #ffffff;
  text-decoration: none;
}

.purpleBtn {
  background: #9513f7;
  color: #ffffff;
  height: 45px;
  line-height: 45px;
  width: 210px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 14px;
  border: none;
  text-align: center;
}

.purpleBtn:hover {
  background: #c054fe;
}

.purpleBtn a {
  color: #ffffff;
  text-decoration: none;
}

.login-submit:hover {
  background: #c054fe;
}

.login-button-half {
  float: left;
  width: 48%;
  margin-right: 1%;
  text-align: center;
  height: 45px;
  line-height: 45px;
  background: #9513f7;
  border-radius: 18px;
  font-size: 12px;
}

.login-button-half:hover {
  background: #c054fe;
}

.login-button-half a {
  color: #ffffff;
  text-decoration: none;
}

#forgotten-password {
  font-size: 11px;
}

#forgotten-password a {
  color: #000000;
  text-decoration: none;
}

#forgotten-password a:hover {
  text-decoration: underline;
}

.memTitle {
  font-size: 22px;
  font-weight: bold;
  line-height: 24px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.memPrice {
  font-size: 22px;
  font-weight: bold;
  line-height: 24px;
  padding-top: 6px;
}

.memSmall {
  font-size: 10px;
  line-height: 10px;
  padding-bottom: 10px;
}

.memLabel {
  font-size: 14px;
}

#main.membership {
  padding: 0px !important;
}

#progressBar {
  width: 100%;
  padding: 30px 0 40px 0;
  height: 100px;
  border-bottom: 1px solid #eeeeee;
  -webkit-box-shadow: 0 3px 15px -6px #333;
  -moz-box-shadow: 0 3px 15px -6px #333;
  box-shadow: 0 3px 15px -6px #333;
}

.progressBarLabel {
  font-size: 13px;
  font-weight: bold;
  color: #20233f;
}

.progress-bar {
  background-color: #1ec4b0;
  padding: 5px;
  border: 3px solid #20233f;
  border-radius: 10px;
}

.progress {
  background-color: #20233f;
  border-radius: 10px;
}

.viewBtn {
  float: left;
  background: #9513f7;
  border: 2px solid #9513f7;
  border-radius: 10px;
  text-align: center;
  display: block;
  width: 70px;
  height: 25px;
  line-height: 25px;
  font-size: 10px;
}

.viewBtn a {
  color: #ffffff !important;
  text-decoration: none;
}

.viewBtnOff {
  float: left;
  border: 2px solid #9513f7;
  border-radius: 10px;
  text-align: center;
  display: block;
  width: 90px;
  height: 25px;
  line-height: 25px;
  font-size: 10px;
}

.viewHide {
  padding-left: 10px;
}

.viewBtnOff a {
  color: #9513f7;
  text-decoration: none;
}

.hideBtn {
  float: left;
  background: #9513f7;
  border: 2px solid #9513f7;
  border-radius: 10px;
  text-align: center;
  display: block;
  width: 70px;
  height: 25px;
  line-height: 25px;
  font-size: 10px;
  margin-left: -20px;
}

.hideBtn a {
  color: #ffffff;
  text-decoration: none;
}

.hideBtnOff {
  float: left;
  border: 2px solid #9513f7;
  border-radius: 10px;
  text-align: center;
  display: block;
  width: 75px;
  padding-left: 10px;
  height: 25px;
  line-height: 25px;
  font-size: 10px;
  margin-left: -20px;
}

.hideBtnOff a {
  color: #9513f7;
  text-decoration: none;
}

.summary {
  font-size: 13px;
  font-weight: bold;
  color: #20233f;
}

.summaryAmount {
  font-size: 32px;
  font-weight: bold;
  line-height: 34px;
}

.summaryAmountAddon {
  font-size: 10px;
  line-height: 34px;
}

#membership-form label {
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  margin-top: 10px;
}

/* .oneRiderTwoHorses,
.fourFamilyFourHorses,
.unaffiliated,
.affiliated,
.limit1500,
.limit3000 {
  float: left;
  padding: 10px 0px 10px 0px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #a60efd;
  background: #ffffff;
  width: 150px;
  height: auto;
  margin-right: 20px;
} */

.oneRiderTwoHorses.off,
.fourFamilyFourHorses.off,
.unaffiliated.off,
.affiliated.off,
.limit1500.off,
.limit3000.off {
  border-color: #989898;
}

.maximum7 {
  font-size: 12px;
}

.dayBtn {
  float: left;
  width: 120px;
  height: 70px;
  line-height: 70px;
  background: #a200ff;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  margin-right: 20px;
  border-radius: 10px;
}

.dayBtn.off {
  background: #b2b2b2;
}

.docsMembership {
  font-size: 12px;
  font-weight: bold;
}

.needHelpMembership {
  font-size: 12px;
  font-weight: bold;
}

.needHelpMembership a {
  color: #a200ff;
  text-decoration: none;
}

.needHelpMembership a:hover {
  text-decoration: underline;
}

.membershipRoundedPink {
  padding: 40px 10px 40px 10px;
  background-color: #f5e3ff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 10px 10px 10px rgba(100, 100, 100, 0.56);
}

.membershipSquarePink {
  padding: 20px 15px 20px 15px;
  background-color: #f5e3ff;
  border: 2px solid #9513f7;
  /* border-radius: 20px; */
  /* border-radius: 30px; */
  font-size: 14px;
  font-weight: bold;
  /* box-shadow: 10px 10px 10px rgba(100, 100, 100, 0.56); */
}

.memberBox {
  /* border: 1px solid #9513f7; #20233f; */
  border: 1px solid #20233f;
  background: #f5e3ff;
}

.membershipRoundedPink a {
  color: #9513f7;
  text-decoration: none;
}

.membershipRoundedPink a:hover {
  text-decoration: underline;
}

.payMntBtn {
  float: left;
  background: #9513f7;
  border: 2px solid #9513f7;
  border-radius: 20px;
  text-align: center;
  display: block;
  width: 120px;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
}

.payMntBtn.small {
  width: 80px;
  height: 25px;
  line-height: 25px;
  font-size: 8px;
}

.payMntBtn a {
  color: #ffffff;
  text-decoration: none;
}

.payMntBtn.off {
  width: 140px;
  background: transparent;
}

.payMntBtn.off.small {
  width: 110px;
  background: transparent;
}

.payMntBtn.off a {
  color: #9513f7;
  text-decoration: none;
}

.payQrtBtn,
.payAnlBtn {
  float: left;
  background: #9513f7;
  border: 2px solid #9513f7;
  border-radius: 20px;
  text-align: center;
  display: block;
  width: 100px;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
}

.payQrtBtn.small,
.payAnlBtn.small {
  width: 60px;
  height: 25px;
  line-height: 25px;
  font-size: 8px;
  margin-left: -30px;
}

.payQrtBtn a,
.payAnlBtn a {
  color: #ffffff;
  text-decoration: none;
}

.payQrtBtn.off,
.payAnlBtn.off {
  width: 110px;
  /*background-color:#f1efec;*/
  background: transparent;
  /* margin-left: -20px; */
  border-left: 0px;
  padding-left: 20px;
}

.payQrtBtn.off.small,
.payAnlBtn.off.small {
  width: 80px;
  height: 25px;
  line-height: 25px;
  font-size: 8px;
}


.payQrtBtn.off a,
.payAnlBtn.off a {
  color: #9513f7;
  text-decoration: none;
}

.summaryAmountBig {
  font-size: 46px;
  line-height: 50px;
}

.unlocked {
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  color: #a200ff;
}

.mmPinkBig {
  font-size: 16px;
}

#sidebarMembership {
  background: #20233f;
  color: #ffffff;
  margin-top: -40px;
}

#sidebarMembership .summary {
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
}

.sidebar-inline {
  float: left;
  margin-right: 1%;
  width: 39%;
  border-bottom: 1px solid #ffffff;
}

@media screen and (min-width: 600px) {

  .sidebar-inline-whole,
  .sidebar-inline {
    font-size: 10px;
  }

  .dropZone {
    width: 80%;
  }
}

.sidebar-inline.two {
  width: 29%;
}

.sidebar-inline.three {
  width: 29%;
  margin-right: 0;
}

.sidebar-inline.center {
  text-align: center;
}

.sidebar-inline a {
  color: #ffffff;
  text-decoration: underline;
}

a.sidebar-question {
  background: #ffffff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: block;
  color: #20233f;
  text-decoration: none;
}

.sidebar-inline-whole {
  width: 100%;
  border-bottom: 1px solid #1f8080;
  color: #1f8080;
}

.sidebar-inline-whole.white {
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
}

.membership-addon-list {
  float: left;
  margin-left: 10px;
  font-size: 14px;
}

.membership-addon-list.narrow {
  font-size: 12px;
}

.dropZone {
  text-align: center;
  height: auto;
  padding: 30px 0 30px 0;
  background: #ffffff;
  border: none;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.scrollBox {
  height: 200px;
  overflow-y: auto;
  background: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 20px;
  font-size: 14px;
  text-align: left;
}

#horsesForm,
#familyForm {
  display: none;
}

#welcome {
  padding: 0px;
  width: 100%;
  height: 638px;
}

.wrapper.welcome {
  background: url(../images/welcome-bg.jpg) no-repeat;
  background-position: right center;
  height: 696px;
}

#welcomeText {
  width: 50%;
  padding: 50px 5% 0 0;
  font-size: 14px;
  color: #2d304a;
}

#welcomeText h1 {
  font-size: 46px;
  font-weight: bold;
  line-height: 50px;
}

#welcomeText a {
  color: #2d304a;
  font-weight: bold;
  text-decoration: underline;
}

.welcomeTextBig {
  font-size: 18px;
}

.purple {
  color: #9513f7;
}

#phone-contact {
  width: 100%;
  background: #20233f;
  padding: 20px 0 20px 0;
  color: #ffffff;
  box-shadow: 10px 10px 10px rgba(100, 100, 100, 0.56);
  margin-bottom: 30px;
}

#phone-contact-left {
  float: left;
  border-right: 1px solid #ffffff;
  padding-right: 30px;
  margin-right: 30px;
}

#phone-contact-right {
  float: left;
}

#slider {
  margin-top: 80px;
}

.carousel1 {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.carousel1 h3 {
  width: 80%;
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.carousel1 li span {
  width: 80%;
  display: block;
  margin: 5px;
  font-size: 12px;
}

.next,
.prev {
  cursor: pointer;
  float: left;
  width: 10px;
  font-size: 56px;
  margin-top: 100px;
  color: #9513f7;
}

.prev {
  margin-right: 30px;
}

.next {
  margin-left: -20px;
}

.carousel-frame {
  float: left;
}

/*  
        .carousel-pagination li {
            display:block;
            width:10px;
            height:10px;
            margin-right:5px;
            cursor:pointer;
            float:left;
            background:#333;
        }
        
        .carousel-pagination .carousel-pagination-active {
            background:#ff0000;
        }
		
		*/

#footer {
  font-size: 14px;
  padding: 30px 0 30px 0;
  background: #e7e3dd;
}

#footer a {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}

#footer a:hover {
  text-decoration: underline;
}

.footerDisclaimer {
  font-size: 11px;
}

body {
  font-family: Poppins;
  background: #f1efec;
}


.modal a.close-modal {
  right: 20px !important;
  top: 20px !important;
}

.modal.narrow {
  max-width: 40% !important;
}



@media screen and (max-width: 768px) {

  .wrapper {
    width: 98%;
    margin: 0 auto;
  }

  #desktopMenu {
    display: none;
  }

  #mobileToggle {
    display: block;
  }

  .mobileToggle {
    display: block;
  }

  .menu-hamburger {
    font-size: 26px;
    color: #ffffff;
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #reviewsio {
    margin-bottom: 30px;
  }

  #mobileMenu {
    position: absolute;
    top: 135px;
    right: 0px;
    width: 80%;
    background: #1ec4b0;
  }

  #mobileMenu ul li {
    list-style-type: none;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ffffff;
    font-size: 14px;
    padding: 0px;
    padding-left: 5%;
  }

  #mobileMenu ul li a {
    color: #ffffff;
    text-decoration: none;
  }

  .login-modal {
    width: 100%;
  }

  #progressBar {
    height: 120px;
  }

  .desktop {
    display: none;
  }

  #membership-form,
  #sidebarMembership {
    margin-top: 40px;
  }

  .membershipRoundedPink {
    margin-bottom: 30px;
  }

  #sidebarMembership {
    padding-bottom: 50px;
  }

  .wrapper.welcome {
    background: none;
    height: auto;
  }

  #welcomeText {
    width: 98%;
    padding: 1%;
  }

  #phone-contact-left {
    float: left;
    width: 40%;
    padding: 0px;
    margin: 0px;
    font-size: 14px;
  }

  #phone-contact-right {
    float: left;
    width: 50%;
    font-size: 14px;
    padding-left: 5px;
  }

  .next,
  .prev {
    display: none;
  }

  .carousel1 li {
    margin-bottom: 30px;
    max-width: 100%;
  }

}

i.fa.fa-question-circle:hover {
  color: #1ec4b0;
}

.bigName a {
  color: #ffffff;
}

.input-group input.login-field {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend {
  border: 1px solid #9513f7;
  border-radius: 5px;
  height: 35px;
  line-height: 35px;
}

.payBtnsBig div:nth-child(2) {
  margin-left: -25px;
}

.payBtnsBig div:nth-child(3) {
  margin-left: -20px;
}

div.off:nth-child(2),
div.off:nth-child(3) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
