// In this scss you can overwrite all our scss, we suggested you to add your scss into this, so whenever the update comes it will not affect your code.
.modal a.close-modal {
  position: absolute;
  top: 0px;
  right: -12.5px;
  display: block;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA3hJREFUaAXlm8+K00Acx7MiCIJH/yw+gA9g25O49SL4AO3Bp1jw5NvktC+wF88qevK4BU97EmzxUBCEolK/n5gp3W6TTJPfpNPNF37MNsl85/vN/DaTmU6PknC4K+pniqeKJ3k8UnkvDxXJzzy+q/yaxxeVHxW/FNHjgRSeKt4rFoplzaAuHHDBGR2eS9G54reirsmienDCTRt7xwsp+KAoEmt9nLaGitZxrBbPFNaGfPloGw2t4JVamSt8xYW6Dg1oCYo3Yv+rCGViV160oMkcd8SYKnYV1Nb1aEOjCe6L5ZOiLfF120EjWhuBu3YIZt1NQmujnk5F4MgOpURzLfAwOBSTmzp3fpDxuI/pabxpqOoz2r2HLAb0GMbZKlNV5/Hg9XJypguryA7lPF5KMdTZQzHjqxNPhWhzIuAruOl1eNqKEx1tSh5rfbxdw7mOxCq4qS68ZTjKS1YVvilu559vWvFHhh4rZrdyZ69Vmpgdj8fJbDZLJpNJ0uv1cnr/gjrUhQMuI+ANjyuwftQ0bbL6Erp0mM/ny8Fg4M3LtdRxgMtKl3jwmIHVxYXChFy94/Rmpa/pTbNUhstKV+4Rr8lLQ9KlUvJKLyG8yvQ2s9SBy1Jb7jV5a0yapfF6apaZLjLLcWtd4sNrmJUMHyM+1xibTjH82Zh01TNlhsrOhdKTe00uAzZQmN6+KW+sDa/JD2PSVQ873m29yf+1Q9VDzfEYlHi1G5LKBBWZbtEsHbFwb1oYDwr1ZiF/2bnCSg1OBE/pfr9/bWx26UxJL3ONPISOLKUvQza0LZUxSKyjpdTGa/vDEr25rddbMM0Q3O6Lx3rqFvU+x6UrRKQY7tyrZecmD9FODy8uLizTmilwNj0kraNcAJhOp5aGVwsAGD5VmJBrWWbJSgWT9zrzWepQF47RaGSiKfeGx6Szi3gzmX/HHbihwBser4B9UJYpFBNX4R6vTn3VQnez0SymnrHQMsRYGTr1dSk34ljRqS/EMd2pLQ8YBp3a1PLfcqCpo8gtHkZFHKkTX6fs3MY0blKnth66rKCnU0VRGu37ONrQaA4eZDFtWAu2fXj9zjFkxTBOo8F7t926gTp/83Kyzzcy2kZD6xiqxTYnHLRFm3vHiRSwNSjkz3hoIzo8lCKWUlg/YtGs7tObunDAZfpDLbfEI15zsEIY3U/x/gHHc/G1zltnAgAAAABJRU5ErkJggg==);
}

@media (min-width: 600px) {

  .modal-lg,
  .modal-xl {
    max-width: 80%;
  }

  .h3,
  h3 {
    font-size: 1.4rem;
  }

  .modal-content {
    border-radius: 15px;
  }
}

.special-radio label.btn {
  height: 70px;
  border-radius: 10px;
  font-weight: bold;
  vertical-align: middle;
  line-height: 54px !important;
  min-width: 100px;
}

.special-radio label.btn.active {
  background: #a200ff !important;;
  color: #fff !important;;
}

.rider-radio label {
  float: left;
  padding: 10px 0px 10px 0px;
  text-align: center;
  border-radius: 10px;
  background: #ffffff;
  width: 150px;
  height: auto;
  margin-right: 20px;
}

.rider-radio label.active {
  border: 1px solid #a60efd;
}

.progress {
  height: auto;
}

#horsesForm,
#familyForm {
  display: inline-block !important;
}

.card-boder {
  text-align: left;
  box-shadow: rgb(100 100 100 / 56%) 10px 10px 10px;
  border: 4px solid rgb(162, 0, 255);
}

.login-field {
  padding: 5px;
}

.dayBtn.off {
  cursor: pointer;
}

.card {
  border-radius: 10px;
}

.main-button {
  padding: 15px 25px 15px 25px !important;
}

.card-price {
  font-size: 23px !important;
}

button:disabled,
button[disabled] {
  border: 1px solid #878787;
  background-color: #e7bcff;
  color: #878787;
}

// i.fa {
//   color: #d58cff;
// }

i.fa:not(.fa-2x) {
  cursor: pointer;
}

.btn {
  border-radius: 25px;
}

@media (min-width: 600px) {
  .btn.btn-primary.btn-lg {
    min-width: 240px;
  }
}

.list-group {
  max-height: 50vh;
  overflow-y: auto;
}

.ngb-toasts {
  left: 45%;
}

.invalid-feedback {
    display: block;
}
.badge-cancelled {
  background-color: #f80031;
  color: white;
}
.badge-active {
  background-color: rgba(147, 231, 195, 0.5);
  color: #1aae6f;
}
.btn-image {
  background-size: cover;
  background-position: 0px -10px;
  width: 150px;
  height: 90px;
  cursor: pointer;
  position: relative;
}
.bg-card {
  background-image: url(/assets/images/cards.png);
}

.bg-dd {
  background-image: url(/assets/images/direct-debit.png);
}
.btn-image.btn.active {
  border: 1px solid #a200ff;
}