//Buttons

button:focus {
  outline: none;
}

.btn {
  margin-right: 5px;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;

  &.btn-sm {
    width: 35px;
    height: 35px;
    padding: 8px 10px;
    font-size: 14px;
  }

  &.btn-lg {
    width: 50px;
    height: 50px;
    padding: 14px 15px;
    font-size: 18px;
    line-height: 23px;
  }

  &.btn-xl {
    width: 70px;
    height: 70px;
    padding: 14px 15px;
    font-size: 24px;
  }
}

.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 10px;
}

.btn-rounded {
  border-radius: 60px;
  padding: 7px 18px;

  &.btn-lg {
    padding: 0.75rem 1.5rem;
  }

  &.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 12px;
  }

  &.btn-xs {
    padding: 0.25rem 0.5rem;
    font-size: 10px;
  }

  &.btn-md {
    padding: 12px 35px;
    font-size: 16px;
  }
}

.btn-primary,
.btn-warning,
.btn-outline-primary:hover,
.btn-outline-warning:hover {
  color: $white;

  &:hover {
    color: $white;
  }
}

.btn-default.btn-outline {
  color: #333;
  background-color: #fff;
}
.btn-default {
  background-color: #e4e7ea;
  background: #e4e7ea;
  border: 1px solid #e4e7ea;
}
.btn-default.btn-outline:hover {
  background: #e4e7ea;
  opacity: 0.8;
}

// .btn-default {
//   background-color: $info;
// }

.button-group {
  .btn {
    margin-bottom: 5px;
    margin-right: 5px;
  }
}

.no-button-group .btn {
  margin-bottom: 5px;
  margin-right: 0px;
}

.btn .text-active {
  display: none;
}

.btn.active .text-active {
  display: inline-block;
}

.btn.active .text {
  display: none;
}
